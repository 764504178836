import { Component } from '@angular/core';
import { ReportsBrandShareService } from '../service/reports-brand-share.service';


@Component({
    selector: 'reports-brand-share',
    templateUrl: './reports-brand-share.component.html',
    styleUrls: ['./reports-brand-share.component.less'],
    providers: [ReportsBrandShareService],
})
export class ReportsBrandShareComponent {

    constructor(private brandShareService: ReportsBrandShareService) { }

}
