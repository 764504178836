import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiReportService } from '@minubo-portal/modules/api/services/api-report.service';
import { Subject, Observable, combineLatest, BehaviorSubject, from } from 'rxjs';
import { QueryFilter, QuerySettingsComparisonFilter, QuerySettingsTimeFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { MnbQuickTimeFilterValue } from '@shared-lib/modules/filters/components/quick-time-filter/mnb-quick-time-filter.component';
import { LocalSettingsStore } from '@minubo-portal/utils/local-settings-store.util';
import { switchMap, map, takeUntil, tap, take, withLatestFrom, debounceTime } from 'rxjs/operators';
import { ReportsReportPageContext } from '@minubo-portal/modules/reports/pages/report/reports-report-page.component';
import {
    ReportSettings,
    ReportBrandShareData,
    ReportBrandShare
} from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { PortalReport } from '@minubo-portal/modules/api/models/api-report.model';
import { PortalEntity } from '@minubo-portal/modules/api/models/api-portal.model';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { ModelAttribute, ModelMeasure } from '@shared-lib/modules/model/mnb-model.model';
import { MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';
import { TimeFilterService } from '@shared-lib/modules/core/services/time/time-filter.service';
import { DateSpan } from '@shared-lib/modules/core/model/mnb-time.model';
import { ApiDataService } from '@minubo-portal/modules/api/services/api-data.service';
import { ViewSharingService } from '@minubo-portal/modules/portal-modal/view-sharing/service/view-sharing.service';

@Injectable()
export class ReportsBrandShareService implements OnDestroy {

    constructor(
        private route: ActivatedRoute,
        private apiReportService: ApiReportService,
        private modelService: MnbModelService,
        private timeFilterService: TimeFilterService,
        private apiDataService: ApiDataService,
        private settingsStore: LocalSettingsStore<ReportBrandShare>,
        private viewSharingService: ViewSharingService<ReportSettings>,
    ) {
        this.viewSharingService.applyViewSettings$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(reportSettings => {
            const viewSettings = (reportSettings as ReportSettings).brandShare;
            this.loadViewSettings(viewSettings);
        });

        this.context$.pipe(
            map(context => {
                this.loadInitialFromSettings(context.report);
                this.loadFromSettingsStore(context.report);
                this.loadInitialFiltersFromEntity(context.portalEntity);
            }),
            takeUntil(this.destroy$)
        ).subscribe();

        this.availableAttributes$ = this.context$.pipe(
            switchMap(context => {
                const attributeCodes = [context.report.settings.brandShare.shareAttributeCode];
                return from(Promise.all(attributeCodes.map(attributeCode => {
                    return this.modelService.getAttribute(attributeCode);
                }))).pipe(
                    map(attributes => {
                        const attributeMap: { [code: string]: ModelAttribute } = {};
                        attributes.forEach(attribute => {
                            attributeMap[attribute.code] = attribute;
                        });
                        return attributeMap;
                    }));
            })
        );

        this.availableMeasures$ = this.context$.pipe(
            switchMap(context => {
                const measureCodes = context.report.settings.brandShare.shareMeasureCodes;
                return from(Promise.all(measureCodes.map(measureCode => {
                    return this.modelService.getMeasure(measureCode);
                }))).pipe(
                    map(measures => {
                        const measureMap: { [code: string]: ModelMeasure } = {};
                        measures.forEach(measure => {
                            measureMap[measure.code] = measure;
                        });
                        return measureMap;
                    }));
            })
        );

        this.viewSettings$ = combineLatest([this.timeFilter$, this.comparisonFilter$, this.filters$]).pipe(
            map(([timeFilter, comparisonFilter, filters]) => {
                const viewSettings: ReportBrandShare = {
                    timeFilter, comparisonFilter, filters
                };
                return viewSettings;
            })
        );

        this.reportSettings$ = this.viewSettings$.pipe(
            map(viewSettings => {
                const reportSettings = new ReportSettings();
                reportSettings.brandShare = viewSettings;
                return reportSettings;
            }
            ));

        this.viewModel$ = combineLatest([this.loadDone$, this.viewSettings$, this.data$, this.context$, this.availableAttributes$, this.availableMeasures$]).pipe(
            map(([loadDone, viewSettings, data, context, availableAttributes, availableMeasures]) => {
                const filters = viewSettings.filters;
                const combinedTimeFilters = {
                    timeFilter: viewSettings.timeFilter,
                    comparisonFilter: viewSettings.comparisonFilter
                };
                const hasComparisonFilter = viewSettings.comparisonFilter !== null;
                const shareAttributeCode = context.report.settings.brandShare.shareAttributeCode;
                const shareMeasureCode = context.report.settings.brandShare.shareMeasureCodes[0];
                const dateSpan = this.timeFilterService.getTimePeriod(viewSettings.timeFilter);
                const model: BrandShareReportDisplayModel = {
                    loadDone,
                    filters,
                    originalFilters: this.originalFilters,
                    combinedTimeFilters,
                    originalCombinedTimeFilters: this.originalCombinedTimeFilters,
                    hasComparisonFilter,
                    availableAttributes,
                    availableMeasures,
                    shareAttributeCode,
                    shareMeasureCode,
                    data,
                    dateSpan,
                };
                return model;
            }
            ));

        // automatically load data
        this.reportSettings$
            .pipe(
                withLatestFrom(this.context$),
                tap(() => this.loadDone$.next(false)),
                debounceTime(50),
                tap(([reportSettings, _]) => this.viewSharingService.setActiveViewSettings(reportSettings)),
                switchMap(([reportSettings, context]) => this.apiReportService.loadReportData(context.report.id, reportSettings)),
                takeUntil(this.destroy$))
            .subscribe(data => {
                if (data && data.brandShare) {
                    this.data$.next(data.brandShare);
                }
                this.loadDone$.next(true);
            });

    }


    private destroy$: Subject<void> = new Subject<void>();

    context$ = this.route.data.pipe(
        map((data: { context: ReportsReportPageContext }) => data.context),
    );

    private loadDone$ = new BehaviorSubject<boolean>(false);
    private data$ = new BehaviorSubject<ReportBrandShareData>(null);

    private availableAttributes$: Observable<{ [code: string]: ModelAttribute }>;
    private availableMeasures$: Observable<{ [code: string]: ModelMeasure }>;

    private timeFilter$ = new BehaviorSubject<QuerySettingsTimeFilter>(QuerySettingsTimeFilter.getDefault());
    private comparisonFilter$ = new BehaviorSubject<QuerySettingsComparisonFilter>(null);
    private originalCombinedTimeFilters: MnbQuickTimeFilterValue = null;

    private filters$ = new BehaviorSubject<QueryFilter[]>([]);
    private originalFilters: QueryFilter[] = [];

    private viewSettings$: Observable<ReportBrandShare>;
    private reportSettings$: Observable<ReportSettings>;

    public viewModel$: Observable<BrandShareReportDisplayModel>;

    private saveToSettingsStore() {
        this.viewSettings$.pipe(
            withLatestFrom(this.context$),
            take(1)
        ).subscribe(([viewSettings, context]) => {
            this.settingsStore.store('report', context.report.id, viewSettings);
        });
    }

    private loadFromSettingsStore(report: PortalReport): void {
        const viewSettings = this.settingsStore.load('report', report.id);
        this.loadViewSettings(viewSettings);
    }

    private loadViewSettings(viewSettings: ReportBrandShare): void {
        if (!viewSettings) {
            return;
        }
        if (viewSettings.timeFilter) {
            this.timeFilter$.next(viewSettings.timeFilter);
        }
        if (viewSettings.comparisonFilter || viewSettings.comparisonFilter === null) {
            this.comparisonFilter$.next(viewSettings.comparisonFilter);
        }
        if (viewSettings.filters) {
            this.filters$.next(viewSettings.filters);
        }
    }

    private loadInitialFromSettings(report: PortalReport) {
        this.timeFilter$.next(deepCopy(report.settings.brandShare.timeFilter));
        this.comparisonFilter$.next(deepCopy(report.settings.brandShare.comparisonFilter || null));
        this.originalCombinedTimeFilters = {
            'timeFilter': report.settings.brandShare.timeFilter,
            'comparisonFilter': report.settings.brandShare.comparisonFilter || null,
        };
    }

    private loadInitialFiltersFromEntity(portalEntity: PortalEntity) {
        if (this.filters$.getValue().length === 0) {
            this.filters$.next(portalEntity.settings.filters);
        }
        this.originalFilters = portalEntity.settings.filters;
        this.originalFilters.forEach(filter => {
            filter.values = filter.values || [];
        });

        // validate current filters
        const currentFilters = this.filters$.getValue();
        if (!this.validateFilters(currentFilters, this.originalFilters)) {
            this.filters$.next(this.originalFilters);
        }
    }

    public loadFilterValues = (attributeCode: string, filterSearch?: string): Promise<{ values: string[], hasMore?: boolean }> => {
        return this.apiDataService.loadAttributeData(attributeCode, filterSearch).toPromise();
    }

    public onTimeFilterChange(combinedTimeFilters: MnbQuickTimeFilterValue) {
        this.timeFilter$.next(combinedTimeFilters.timeFilter);
        this.comparisonFilter$.next(combinedTimeFilters.comparisonFilter);
        this.saveToSettingsStore();
    }

    public onFilterChange(filter: QueryFilter, index) {
        const currentFilters = this.filters$.getValue();
        currentFilters[index] = filter;
        if (this.validateFilters(currentFilters, this.originalFilters)) {
            this.filters$.next(currentFilters);
        } else {
            this.filters$.next(this.originalFilters);
        }
        this.saveToSettingsStore();
    }

    private validateFilters(currentFilters: QueryFilter[], originalFilters: QueryFilter[]): boolean {
        const isEqualLength = currentFilters.length === originalFilters.length;
        let isSameCodes = false;
        if (isEqualLength) {
            isSameCodes = currentFilters.every((currentFilter, index) => {
                return currentFilter.attributeCode === originalFilters[index].attributeCode;
            });
        }
        return isEqualLength && isSameCodes;
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}

export type BrandShareReportDisplayModel = {
    loadDone: boolean;
    filters: QueryFilter[];
    originalFilters: QueryFilter[];
    combinedTimeFilters: MnbQuickTimeFilterValue;
    originalCombinedTimeFilters: MnbQuickTimeFilterValue;
    hasComparisonFilter: boolean;
    availableAttributes: { [code: string]: ModelAttribute };
    availableMeasures: { [code: string]: ModelMeasure };
    shareAttributeCode: string;
    shareMeasureCode: string;
    data: ReportBrandShareData;
    dateSpan: DateSpan;
};
